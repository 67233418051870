import './AboutMe.css';
import React, { Component } from 'react';
export class AboutMe extends Component {

    render = () => {
        return (
            <div id="aboutme" className="row">
                <div className="column">
                </div>
                <div className="column">
                    <p className="header">ABOUT ME</p>
                    <p className="description">My name is Tarun raja, and I’m a travel photographer and software developer based in New York. I find myself always curious about new technologies, gadgets, and photography techniques. <br/> <br/>I have done my master’s in Computer Engineering at the State University of New York at Binghamton. I started my career in Web Development, and I am currently working at JP Morgan Chase as an Associate Software developer since 2016. </p>
                </div>
            </div>
        )
    }
}

export default AboutMe;
