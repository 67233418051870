import './ImageGrid.css';
import React, { Component } from 'react';
export class ImageGrid extends Component{

    render = () => {
        const Images = [[21,22,
            23,
            138,
            25,
            137],[27,
            140,
            29,
            44,131],[121,
            122,
            35,
            36,
            129 ], [123,
            124,
            125,
            126,
            128]];
        const cssProp = {
            width: "100%"
        };

        return (
            <div  id="gallery" >
            <h1 className="heading">GALLERY</h1>
            <div className="gallery row">

                {Images.map(function(images, index){
                    return <div key={index} className="column">
                        {images.map(function(image, index){
                            return  <img key={image} alt={"Taken By Tarun Raja"} src={"https://tarunphotographypictures.s3.us-east-2.amazonaws.com/"+image+".jpg"} style={cssProp}></img>
                        })}
                    </div>
                })}
            </div>
            </div>
        );
    }
}

export default ImageGrid;
