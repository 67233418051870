import React, {PureComponent} from 'react';
import './Links.css';
import Navigation from "./Navigation";
interface NavigationLink  {
    name: string,
    href: string
}

interface IProps {
    names: NavigationLink[]
}
interface IState {
    isXs: boolean,
    isExpanded: boolean,
    windowSize: WindowSize
}
interface WindowSize {
    width: any,
    height: any
}
export class NavigationGroup extends PureComponent<IProps, IState>{
    private width: any;
    constructor(props: IProps) {
        super(props);
        this.state = {
            isXs: false,
            isExpanded: false,
            windowSize: {
                width: window.innerWidth,
                height: window.innerHeight
            }
        };
    }

    updateSize() {
        this.setState( {"windowSize": {
            width:window.innerWidth,
            height: window.innerHeight
        }});
    }
    componentDidMount(){
        window.addEventListener('resize', this.updateSize.bind(this));
    }
    componentWillUnmount(){
        window.removeEventListener('resize', this.updateSize.bind(this))
    }

    render() {
        const items = [];

       // console.log(this.state.windowSize);
        for (const value of this.props.names) {
            items.push(<Navigation name={value.name} key={value.name} href={value.href} onclick={this.expand.bind(this)}></Navigation>)
        }
        const XsMode = (<div className="navigation-group-xs">
            <div>
                <a onClick={this.expand.bind(this)} rel="popup noopener noreferrer" target="_blank"><i className="icon-menu"></i></a>
                <div>
                    {this.state.isExpanded ? items : "  "}
                </div>
            </div>
        </div>);
        const desktopMode = (<div className="navigation-group">
            {items}
        </div>);
        if(this.state.windowSize.width < 600){
            return XsMode;
        } else {
            return desktopMode;
        }
    }
    expand(){
        this.setState({
            isExpanded: !this.state.isExpanded
        });
    }
}

export default NavigationGroup;
