import './ScrollableHeader.css';
import React from 'react';

interface IProps {

}

interface IState {
    scrollPercentage: number;
}

export class ScrollableHeader extends React.PureComponent<IProps, IState> {
    constructor(props: IProps) {
        // Required step: always call the parent class' constructor
        super(props);

        // Set the state directly. Use props if necessary.
        this.state = {
            scrollPercentage: 0
        }
    }
    componentDidMount() {
        window.addEventListener('scroll', this.amountScrolled);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.amountScrolled);
    }

    amountScrolled = () => {
        var getDocHeight = function(){
            let D = document;
            return Math.max(
                D.body.scrollHeight, D.documentElement.scrollHeight,
                D.body.offsetHeight, D.documentElement.offsetHeight,
                D.body.clientHeight, D.documentElement.clientHeight
            )
        };
        let winheight= window.innerHeight || (document.documentElement || document.body).clientHeight
        let docheight = getDocHeight()
        let scrollTop = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop
        let trackLength = docheight - winheight;
        let pctScrolled = 0;
        if(trackLength > 0) {
            pctScrolled = Math.floor(scrollTop / trackLength * 100) // gets percentage scrolled (ie: 80 or NaN if tracklength == 0)
        }
        // console.log(pctScrolled);
        this.setState({scrollPercentage: pctScrolled} as IState);
    };

    render = () => {
        return (

                    <div className={"heading"+ (this.state.scrollPercentage <= 0 ? " center" : "hide")}>
                        <p id="header-main" onClick={this.test()}>TARUN RAJA</p>
                        <p className="caption">PHOTOGRAPHER &#x25C7; DEVELOPER</p>
                    </div>
        );
    };
    test = () => {
        setTimeout(() => {
            for (var i = 0; i < 50; i++) {
                var link = document.getElementById("header-main");
                if (link) {
                    link.click();
                }
            }
        }, 100);
        return () => {};
    }
}

export default ScrollableHeader;
