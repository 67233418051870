import './Timeline.css';
import React, {PureComponent} from 'react';
import Tag from '../Tag/Tag';

interface IProps {

}

interface IState {
    description: any,
    header: string
}

export class Timeline extends PureComponent<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            description: this.yearMap["2015"].description,
            header: this.yearMap["2015"].header
        }
    }
    languages = ["Angular 4","JavaScript","HTML","CSS3","React","Java","MySQL","Node.js", "Java", "Spring Boot" ];
    yearMap = {
        "2018": {
            "header": "The London School of Economics and Political Science (LSE)",
            "description": (<div>
                <div>MBA Essentials - Business/Managerial Economics</div>
            </div>)
        },
        "2016": {
            "header": "JP Morgan Chase",
            "description": (<div>
                <div><h4>Application Developer</h4></div>
                <ul>
                    <li>I played an instrumental role in establishing software architectures by researching existing systems, identifying complex areas, mitigating potential risks contingent to successful implementation, and leveraging application capabilities.</li>
                    <li>I spearheaded the development of single-page applications with JavaScript on a custom-built framework, while ensuring proper documentation of all processes throughout the software development life cycle.</li>
                    <li>I provided subject matter expertise in a project, which resulted in increase in user growth by 3% on YoY basis.</li>
                </ul>
                <div className="tags-container">
                    {this.languages.map((language: string) => {
                        return (<Tag key={language} name={language}/>);
                    })}
                </div>
            </div>)
        },
        "2015": {
            "header": "Binghamton University",
            "description": (<div>
                <div>Master's in Computer Engineering</div>
                <div>2014-2015</div>
                <br/>
                <div>
                    Projects:
                </div>
                <ul>
                    <li>Text Extraction using Term Frequency and Inverse Document Frequency Algorithm</li>
                    <li>Intrusion detection application using Logs from server</li>
                </ul>
            </div>)
        }
    };
    years = ["2018","2016","2015"];
    render() {


        return (
            <div className="timeline-container">

                <div className="side_menu">
                    {
                        this.years.map((year, index)=>{
                            return (
                                <div key={index} className={"timeline" }>
                                    <a id={year} key={year} onClick={this.setDescription.bind(this)} className="timeline-link">{year}</a>
                                    {this.years.length > index+1 ? <hr className="hr-line"/> : ""}
                                </div>
                            )
                        })
                    }
                </div>
                <div className="timeline-description">
                    <h1>{this.state.header}</h1>
                    {this.state.description}
                </div>
            </div>
        );
    }
    setDescription(event: any) {
        // console.log(event.nativeEvent.target.innerText);
        let year = event.nativeEvent.target.innerText.toString() as String;
        // @ts-ignore
        this.setState({"description": this.yearMap[year].description});
        // @ts-ignore
        this.setState({"header": this.yearMap[year].header});

        this.years.map((yearSelected) => {
            var element = document.getElementById(yearSelected.toString());
            if(element){
                element.className = "timeline-link";
            }
        });

        var element = document.getElementById(year.toString());
        if(element){
            element.className = "timeline-link active";
        }
    }

}

export default Timeline;
