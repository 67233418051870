import React , {Component} from 'react';
import './Tag.css'

interface IProps {
    name: string
}

export class App extends Component<IProps> {

    render() {
        return (<div className="tag">
            <button className="tag-button">{this.props.name}</button>
        </div>)
    }
}

export default App;
