import './SocialMedia.css';
import React, { Component } from 'react';
export class SocialMedia extends Component {

    render = () => {
        return (
            <div id="contact" className="social row">
                <div className="column">
                    <a href="https://www.facebook.com/rajatarun1" rel="noopener noreferrer" aria-label="facebook opens in new tab" target="_blank"><i className="icon-social-facebook"></i></a>
                    <a href="https://www.instagram.com/travelalotlife" rel="noopener noreferrer" aria-label="instagram opens in new tab" target="_blank"><i className="icon-social-instagram"></i></a>
                </div>
                <div className="column">
                    <a href="https://www.linkedin.com/in/tarun-raja" rel="noopener noreferrer" aria-label="linkedin opens in new tab" target="_blank"><i className="icon-social-linkedin"></i></a>
                    <a href="https://github.com/rajatarun12" rel="noopener noreferrer" aria-label="github opens in new tab" target="_blank"><i className="icon-social-github"></i></a>
                </div>
            </div>
        )
    }
}

export default SocialMedia;
