import './App.css';
import React from 'react';
import NavigationGroup from "./Modules/Navigation/NavigarionGroup";
import ScrollableHeader from "./Modules/ScrollableHeader/ScrollableHeader";
import ImageGrid from "./Modules/ImageGrid/ImageGrid";
import AboutMe from "./Modules/AboutMe/AboutMe";
import SocialMedia from "./Modules/SocialMedia/SocialMedia";
import Timeline from "./Modules/Timeline/Timeline";

interface IProps {

}

interface IState {
    scrollPercentage: number;
}

export class App extends React.PureComponent<IProps, IState> {

    render = () => {
        var navigationLinks = [{ name : "HOME", href: "#home"},{name: "ABOUT", href: "#about"}, {name: "EXPERIENCE", href: "#experience"},
            {name: "GALLERY", href: "#gallery"},{name: "CONTACT", href: "#contact"}
           ];
        return (
            <div key="App" className="App">
                {/*<div>*/}
                {/**/}
                {/*</div>*/}
                <div key="home" id="home" className="heading-container">
                    <ScrollableHeader key={"ScrollableHeader"}></ScrollableHeader>
                    <div key="navigation" className="navigation">
                        <NavigationGroup key={"NavigationGroup"} names={navigationLinks}> </NavigationGroup>
                    </div>
                </div>
                <div key="about" id="about" className="body">
                    <AboutMe key={"AboutMe"}></AboutMe>
                </div>
                <div key="experience" id="experience" className="body">
                    <Timeline key={"Timeline"}></Timeline>
                </div>
                <div key="gallery" className="gallery">
                   <ImageGrid key={"ImageGrid"}></ImageGrid>
                </div>
                <div key="body" className="body">
                    <SocialMedia key={"SocialMedia"}></SocialMedia>
                </div>
            </div>
        );
    }
}

export default App;
