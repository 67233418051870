import React from 'react';
import './Links.css';
type NavigationLink = {
    name: string,
    href: string,
    onclick: any
}
const Navigation: React.FC<NavigationLink> = ({name, href, onclick}) => {
    return (
       <div>
           <a className="navigation-link" onClick={onclick} href={href}>{name}</a>
       </div>
);
};

export default Navigation;
